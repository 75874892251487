import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDo0PY1nUIfOgBgZME2TuziJEyL5uMPOgE',
  authDomain: 'tcaster-push-notifications.firebaseapp.com',
  projectId: 'tcaster-push-notifications',
  storageBucket: 'tcaster-push-notifications.appspot.com',
  messagingSenderId: '140301566634',
  appId: '1:140301566634:web:bbae90e4268c10ecd886d3',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
