export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

async function fetch2(path, method = METHOD.GET, body, token) {
  const options = {
    method,
    // mode: 'no-cors',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      token ? { Authorization: token } : {}
    ),
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  console.log('url is ', method, path, options);
  console.time(`time for url ${path}`);
  const res = await fetch(path, options);
  try {
    console.timeEnd(`time for url ${path}`);
  } catch (e) {
    console.log(e);
  }
  if (!res.ok) throw new Error(res.statusText);
  const resp = await res.json();
  return resp;
}

export default fetch2;
