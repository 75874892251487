import React from 'react';
import { Form, Icon, Input, Button, Select, Card, TreeSelect } from 'antd';
import styled from 'styled-components';

import TOPICS from './eduGroup';

import { sendPush } from './api';

const Div = styled.div`
  max-width: 700px;
  margin: 2em;
`;

const FormItem = Form.Item;
const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const treeData = TOPICS;

class PushForm extends React.Component {
  state = {
    loading: false,
    error: null,
  };
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: null }, () => {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          try {
            await sendPush(values);
            this.setState({ loading: false, error: null });
          } catch (error) {
            console.error(error);
            this.setState({ loading: false, error: error });
          }
        } else {
          this.setState({ loading: false, error: null });
        }
      });
    });
  };

  render() {
    const { loading, error } = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const userNameError = isFieldTouched('title') && getFieldError('title');
    const passwordError = isFieldTouched('content') && getFieldError('content');

    return (
      <Card title="การแจ้งเตือน" style={{ maxWidth: 640, margin: '2em' }}>
        {error && <div>{'การส่ง Push Notification มีปัญหา'}</div>}
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="หัวข้อ">
            {getFieldDecorator('topic', {
              rules: [{ required: true, message: 'โปรดเลือกหัวข้อ' }],
            })(
              <TreeSelect
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                placeholder="Please select"
                treeDefaultExpandAll
              />
            )}
          </FormItem>
          <FormItem label="ประเภท">
            {getFieldDecorator('type', {
              initialValue: 'URL',
              rules: [{ required: true, message: 'โปรดเลือกประเภท' }],
            })(
              <Select>
                <Option value="URL">ลิงค์</Option>
                <Option value="ALERT">แจ้งเตือน</Option>
              </Select>
            )}
          </FormItem>
          <FormItem
            label="ชื่อของการแจ้งเตือน"
            validateStatus={userNameError ? 'error' : ''}
            help={userNameError || ''}
          >
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'โปรดใส่หัวข้อ' }],
            })(<Input placeholder="หัวข้อที่จะโชว์ในแอป" />)}
          </FormItem>
          <FormItem
            label="URL ของการแจ้งเตือนประเภทลิงค์"
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError || ''}
          >
            {getFieldDecorator('content')(
              <Input type="url" placeholder="http://news.tcaster.net/" />
            )}
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              loading={loading}
            >
              ส่งการแจ้งเตือน
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const PushFormWithForm = Form.create()(PushForm);

export default PushFormWithForm;
