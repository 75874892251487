import React, { Component } from 'react';
import { Layout, Menu, Button } from 'antd';
import styled from 'styled-components';

import FirebaseProvider, {
  FirebaseUserProvider,
  useFirebaseUserContext,
} from './libs/FirebaseProvider';

import Form from './Routes/PushForm';
import Login from './Routes/Login';

import logo from './icon512.png';

import './App.css';

const { Header, Footer, Sider, Content } = Layout;

const MainLayout = styled(Layout)`
  height: 100vh;
`;

const Heading = styled.h1`
  color: white;
  padding-left: 3em;
`;

const IMG = styled.img`
  height: 64px;
  float: left;
`;

const UIApp = () => {
  const { user, signOut } = useFirebaseUserContext();
  console.log(user);
  return (
    <MainLayout>
      <Header
        style={{
          backgroundColor: '#ff5a5a',
        }}
      >
        <IMG src={logo} />
        <Heading>TCASter Control Panel</Heading>
      </Header>
      <Layout>
        <Content>
          <>
            {user && <Form />}
            {!user && <Login />}
            {user && (
              <Button
                onClick={() => {
                  signOut();
                }}
              >
                Logout
              </Button>
            )}
          </>
        </Content>
      </Layout>
      <Footer></Footer>
    </MainLayout>
  );
};

export default function App() {
  return (
    <FirebaseProvider>
      <FirebaseUserProvider>
        <UIApp />
      </FirebaseUserProvider>
    </FirebaseProvider>
  );
}
