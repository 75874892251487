import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext, FirebaseUserContext } from './context';
import firebase from '../Firebase';

export const FirebaseProvider = ({ children }) => {
  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export const FirebaseUserProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unSubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        console.log('authUser', authUser);
        setUser(authUser);
      } else {
        // No user is signed in.
        console.log('authUser', null);
        setUser(null);
      }
    });
    return () => {
      unSubscribe();
    };
  }, [firebase]);
  return <FirebaseUserContext.Provider value={user}>{children}</FirebaseUserContext.Provider>;
};

export default FirebaseProvider;
