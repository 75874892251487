import { useContext } from 'react';
import { FirebaseContext, FirebaseUserContext } from './context';

export const useFirebaseContext = () => {
  const firebase = useContext(FirebaseContext);
  return firebase;
};

export const useFirebaseUserContext = () => {
  const firebase = useFirebaseContext();
  const user = useContext(FirebaseUserContext);

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      return userCredential;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const signOut = () => firebase.auth().signOut();

  return { user, loginWithEmailAndPassword, signOut };
};
