const EDU_GROUP = [
  {
    label: 'Global (ทุกคนได้)',
    value: { v: 1, topic: 'GLOBAL' },
    key: 'GLOBAL',
  },
  {
    label: 'กลุ่มการศึกษา (Tag)',
    value: '0-1',
    selectable: false,
    key: 'EDUTAG',
    children: [
      {
        value: { topicType: 'EDUTAG', topicId: 2 },
        key: 'EDUTAG:2',
        label: 'การแพทย์และเภสัชศาสตร์',
        children: [
          {
            value: { topicType: 'EDUTAG', topicId: 1 },
            label: 'คณะแพทยศาสตร์',
            key: 'EDUTAG:1',
          },
          {
            value: { topicType: 'EDUTAG', topicId: 3 },
            label: 'คณะทันตแพทยศาสตร์',
            key: 'EDUTAG:3',
          },
          {
            value: { topicType: 'EDUTAG', topicId: 12 },
            label: 'คณะสัตวแพทย์',
            key: 'EDUTAG:12',
          },
          {
            value: { topicType: 'EDUTAG', topicId: 9 },
            label: 'คณะเภสัชศาสตร์',
            key: 'EDUTAG:9',
          },
          {
            value: { topicType: 'EDUTAG', topicId: 40 },
            label: 'กลุ่มกสพท.',
            key: 'EDUTAG:40',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 4 },
        key: 'EDUTAG:4',
        label: 'วิทยาศาสตร์และเทคโนโลยีสารสนเทศ',
        children: [
          {
            key: 'EDUTAG:5',
            value: { topicType: 'EDUTAG', topicId: 5 },
            label: 'คณะวิทยาศาสตร์ทั่วไป (กายภาพ/ชีวภาพ)',
          },
          {
            key: 'EDUTAG:7',
            value: { topicType: 'EDUTAG', topicId: 7 },
            label: 'คณะวิทยาศาสต์วิทยาการคอมพิวเตอร์ และเทคโนโลยีสารสนเทศ',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 6 },
        key: 'EDUTAG:6',
        label: 'วิศวกรรมศาสตร์',
        children: [
          {
            key: 'EDUTAG:14',
            value: { topicType: 'EDUTAG', topicId: 14 },
            label: 'คณะวิศวกรรมศาสตร์',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 11 },
        key: 'EDUTAG:11',
        label: 'วิทยาศาสตร์สุขภาพ',
        children: [
          {
            key: 'EDUTAG:8',
            value: { topicType: 'EDUTAG', topicId: 8 },
            label: 'คณะพยาบาลศาสตร์',
          },
          {
            key: 'EDUTAG:10',
            value: { topicType: 'EDUTAG', topicId: 10 },
            label: 'คณะวิทยาศาสตร์การกีฬา',
          },
          {
            key: 'EDUTAG:13',
            value: { topicType: 'EDUTAG', topicId: 13 },
            label: 'คณะสหเวชศาสตร์และเทคนิคการแพทย์',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 15 },
        key: 'EDUTAG:15',
        label: 'สถาปัตยกรรมศาสตร์',
        children: [
          {
            key: 'EDUTAG:16',
            value: { topicType: 'EDUTAG', topicId: 16 },
            label: 'คณะสถาปัตยกรรมศาสตร์',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 17 },
        key: 'EDUTAG:17',
        label: 'เกษตรศาสตร์และประมง',
        children: [
          {
            key: 'EDUTAG:18',
            value: { topicType: 'EDUTAG', topicId: 18 },
            label: 'คณะเกษตรศาสตร์/วนศาสตร์/เทคโนโลยี และอุตสาหกรรมการเกษตร',
          },
          {
            key: 'EDUTAG:20',
            value: { topicType: 'EDUTAG', topicId: 20 },
            label: 'คณะประมง',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 19 },
        key: 'EDUTAG:19',
        label: 'บริหารบัญชี และเศรษฐศาสตร์',
        children: [
          {
            key: 'EDUTAG:21',
            value: { topicType: 'EDUTAG', topicId: 21 },
            label: 'คณะบริหารธุรกิจ พาณิชยศาสตร์ และการบัญชี',
          },
          {
            key: 'EDUTAG:23',
            value: { topicType: 'EDUTAG', topicId: 23 },
            label: 'คณะเศรษฐศาสตร์',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 22 },
        key: 'EDUTAG:22',
        label: 'ท่องเที่ยวและการโรงแรม',
        children: [
          {
            key: 'EDUTAG:24',
            value: { topicType: 'EDUTAG', topicId: 24 },
            label: 'คณะการท่องเที่ยวและโรงแรม',
          },
          {
            key: 'EDUTAG:39',
            value: { topicType: 'EDUTAG', topicId: 39 },
            label: 'จัดการธุรกิจการบิน',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 25 },
        key: 'EDUTAG:25',
        label: 'ครุศาสตร์',
        children: [
          {
            key: 'EDUTAG:26',
            value: { topicType: 'EDUTAG', topicId: 26 },
            label: 'คณะครุศาสตร์และศึกษาศาสตร์',
          },
          {
            key: 'EDUTAG:27',
            value: { topicType: 'EDUTAG', topicId: 27 },
            label: 'คณะพลศึกษาและสุขศึกษา',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 28 },
        key: 'EDUTAG:28',
        label: 'ศิลปกรรมศาสตร์',
        children: [
          {
            key: 'EDUTAG:29',
            value: { topicType: 'EDUTAG', topicId: 29 },
            label: 'คณะศิลปกรรมศาสตร์และวิจิตรศิลป์',
          },
          {
            key: 'EDUTAG:30',
            value: { topicType: 'EDUTAG', topicId: 30 },
            label: 'คณะศิลปประยุกต์และการออกแบบ',
          },
          {
            key: 'EDUTAG:31',
            value: { topicType: 'EDUTAG', topicId: 31 },
            label: 'คณะดุริยางคศิลป์',
          },
        ],
      },
      {
        value: { topicType: 'EDUTAG', topicId: 32 },
        key: 'EDUTAG:32',
        label: 'มนุษยศาสตร์และสังคมศาสตร์',
        children: [
          {
            key: 'EDUTAG:33',
            value: { topicType: 'EDUTAG', topicId: 33 },
            label: 'คณะอักษรศาสตร์และศิลปศาสตร์',
          },
          {
            key: 'EDUTAG:34',
            value: { topicType: 'EDUTAG', topicId: 34 },
            label: 'คณะนิเทศศาสตร์และวารสารศาสตร์',
          },
          {
            key: 'EDUTAG:35',
            value: { topicType: 'EDUTAG', topicId: 35 },
            label: 'คณะรัฐศาสตร์',
          },
          {
            key: 'EDUTAG:36',
            value: { topicType: 'EDUTAG', topicId: 36 },
            label: 'คณะนิติศาสตร์',
          },
          {
            key: 'EDUTAG:37',
            value: { topicType: 'EDUTAG', topicId: 37 },
            label: 'คณะจิตวิทยา',
          },
          {
            key: 'EDUTAG:38',
            value: { topicType: 'EDUTAG', topicId: 38 },
            label: 'คณะมนุษยศาสตร์และสังคมศาสตร์',
          },
        ],
      },
    ],
  },
];

const UNIVERSITIES = [
  {
    key: 'UNIVERSITY:313',
    value: { topicType: 'UNIVERSITY', topicId: 313 },
    label: 'จุฬาลงกรณ์มหาวิทยาลัย',
  },
  {
    key: 'UNIVERSITY:314',
    value: { topicType: 'UNIVERSITY', topicId: 314 },
    label: 'มหาวิทยาลัยเกษตรศาสตร์',
  },
  {
    key: 'UNIVERSITY:393',
    value: { topicType: 'UNIVERSITY', topicId: 393 },
    label: 'มหาวิทยาลัยกาฬสินธุ์',
  },
  {
    key: 'UNIVERSITY:315',
    value: { topicType: 'UNIVERSITY', topicId: 315 },
    label: 'มหาวิทยาลัยขอนแก่น',
  },
  {
    key: 'UNIVERSITY:316',
    value: { topicType: 'UNIVERSITY', topicId: 316 },
    label: 'มหาวิทยาลัยเชียงใหม่',
  },
  {
    key: 'UNIVERSITY:317',
    value: { topicType: 'UNIVERSITY', topicId: 317 },
    label: 'มหาวิทยาลัยทักษิณ',
  },
  {
    key: 'UNIVERSITY:318',
    value: { topicType: 'UNIVERSITY', topicId: 318 },
    label: 'มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี',
  },
  {
    key: 'UNIVERSITY:319',
    value: { topicType: 'UNIVERSITY', topicId: 319 },
    label: 'มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าพระนครเหนือ',
  },
  {
    key: 'UNIVERSITY:320',
    value: { topicType: 'UNIVERSITY', topicId: 320 },
    label: 'มหาวิทยาลัยเทคโนโลยีสุรนารี',
  },
  {
    key: 'UNIVERSITY:321',
    value: { topicType: 'UNIVERSITY', topicId: 321 },
    label: 'มหาวิทยาลัยธรรมศาสตร์',
  },
  {
    key: 'UNIVERSITY:322',
    value: { topicType: 'UNIVERSITY', topicId: 322 },
    label: 'มหาวิทยาลัยนครพนม',
  },
  {
    key: 'UNIVERSITY:323',
    value: { topicType: 'UNIVERSITY', topicId: 323 },
    label: 'มหาวิทยาลัยนราธิวาสราชนครินทร์',
  },
  {
    key: 'UNIVERSITY:324',
    value: { topicType: 'UNIVERSITY', topicId: 324 },
    label: 'มหาวิทยาลัยนเรศวร',
  },
  {
    key: 'UNIVERSITY:325',
    value: { topicType: 'UNIVERSITY', topicId: 325 },
    label: 'มหาวิทยาลัยบูรพา',
  },
  {
    key: 'UNIVERSITY:326',
    value: { topicType: 'UNIVERSITY', topicId: 326 },
    label: 'มหาวิทยาลัยพะเยา',
  },
  {
    key: 'UNIVERSITY:327',
    value: { topicType: 'UNIVERSITY', topicId: 327 },
    label: 'มหาวิทยาลัยมหาสารคาม',
  },
  {
    key: 'UNIVERSITY:328',
    value: { topicType: 'UNIVERSITY', topicId: 328 },
    label: 'มหาวิทยาลัยมหิดล',
  },
  {
    key: 'UNIVERSITY:392',
    value: { topicType: 'UNIVERSITY', topicId: 392 },
    label: 'มหาวิทยาลัยแม่โจ้',
  },
  {
    key: 'UNIVERSITY:329',
    value: { topicType: 'UNIVERSITY', topicId: 329 },
    label: 'มหาวิทยาลัยแม่ฟ้าหลวง',
  },
  {
    key: 'UNIVERSITY:330',
    value: { topicType: 'UNIVERSITY', topicId: 330 },
    label: 'มหาวิทยาลัยวลัยลักษณ์',
  },
  {
    key: 'UNIVERSITY:331',
    value: { topicType: 'UNIVERSITY', topicId: 331 },
    label: 'มหาวิทยาลัยศรีนครินทรวิโรฒ',
  },
  {
    key: 'UNIVERSITY:332',
    value: { topicType: 'UNIVERSITY', topicId: 332 },
    label: 'มหาวิทยาลัยศิลปากร',
  },
  {
    key: 'UNIVERSITY:333',
    value: { topicType: 'UNIVERSITY', topicId: 333 },
    label: 'มหาวิทยาลัยสงขลานครินทร์',
  },
  {
    key: 'UNIVERSITY:352',
    value: { topicType: 'UNIVERSITY', topicId: 352 },
    label: 'มหาวิทยาลัยสวนดุสิต',
  },
  {
    key: 'UNIVERSITY:334',
    value: { topicType: 'UNIVERSITY', topicId: 334 },
    label: 'มหาวิทยาลัยอุบลราชธานี',
  },
  {
    key: 'UNIVERSITY:391',
    value: { topicType: 'UNIVERSITY', topicId: 391 },
    label: 'ราชวิทยาลัยจุฬาภรณ์',
  },
  {
    key: 'UNIVERSITY:335',
    value: { topicType: 'UNIVERSITY', topicId: 335 },
    label: 'สถาบันเทคโนโลยีพระจอมเกล้าเจ้าคุณทหารลาดกระบัง',
  },
];

const TCAS_ROUNDS = [
  {
    key: 'TCAS_ROUND:1_1',
    value: { topicType: 'TCAS_ROUND', topicId: 1 },
    label: 'Tcas รอบ 1/1',
  },
  {
    key: 'TCAS_ROUND:1_2',
    value: { topicType: 'TCAS_ROUND', topicId: 1 },
    label: 'Tcas รอบ 1/2',
  },
  {
    key: 'TCAS_ROUND:2',
    value: { topicType: 'TCAS_ROUND', topicId: 2 },
    label: 'Tcas รอบ 2',
  },
  {
    key: 'TCAS_ROUND:3',
    value: { topicType: 'TCAS_ROUND', topicId: 3 },
    label: 'Tcas รอบ 3',
  },
  {
    key: 'TCAS_ROUND:4',
    value: { topicType: 'TCAS_ROUND', topicId: 4 },
    label: 'Tcas รอบ 4',
  },
  {
    key: 'TCAS_ROUND:5',
    value: { topicType: 'TCAS_ROUND', topicId: 5 },
    label: 'Tcas รอบ 5',
  },
];

const EXAMS = [
  {
    key: 'EXAM:23',
    value: { topicType: 'EXAM', topicId: 23 },
    label: 'สอบ GAT',
  },
  {
    key: 'EXAM:67',
    value: { topicType: 'EXAM', topicId: 67 },
    label: 'สอบ PAT',
  },
  {
    key: 'EXAM:65',
    value: { topicType: 'EXAM', topicId: 65 },
    label: 'สอบ O-Net',
  },
  {
    key: 'EXAM:64',
    value: { topicType: 'EXAM', topicId: 64 },
    label: 'สอบ 9 วิชาสามัญ',
  },
  {
    key: 'EXAM:69',
    value: { topicType: 'EXAM', topicId: 69 },
    label: 'สอบ English',
  },
  {
    key: 'EXAM:2',
    value: { topicType: 'EXAM', topicId: 2 },
    label: 'GPAX เกรดเฉลี่ย',
  },
  {
    key: 'EXAM:63',
    value: { topicType: 'EXAM', topicId: 63 },
    label: 'GPA เกรด',
  },
  {
    key: 'EXAM:37',
    value: { topicType: 'EXAM', topicId: 37 },
    label: 'สอบ กสพท. (วิชาเฉพาะแพทย์)',
  },
  {
    key: 'EXAM:70',
    value: { topicType: 'EXAM', topicId: 70 },
    label: 'สอบ มอ. (วิชาเฉพาะของม.สงขลา)',
  },
];

export default EDU_GROUP;

export { EDU_GROUP, UNIVERSITIES, TCAS_ROUNDS, EXAMS };
