import React, { useEffect, useState } from 'react';
import { Form, Icon, Input, Button, Select, Card, TreeSelect } from 'antd';

import { useFirebaseUserContext } from '../../libs/FirebaseProvider';

const FormItem = Form.Item;
const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const LoginForm = React.forwardRef((props, ref) => {
  const [apiFetcher, setApiFetcher] = useState({ loading: false, error: null });
  const { user, loginWithEmailAndPassword } = useFirebaseUserContext();

  const { loading, error } = apiFetcher;
  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;

  // Only show error after a field is touched.
  const userNameError = isFieldTouched('title') && getFieldError('title');
  const passwordError = isFieldTouched('content') && getFieldError('content');

  useEffect(() => {
    props.form.validateFields();
    return () => {};
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiFetcher({ loading: true, error: null });
    props.form.validateFields(async (err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        try {
          // await sendPush(values);
          const { email, password } = values;
          await loginWithEmailAndPassword(email, password);
          setApiFetcher({ loading: false, error: null });
        } catch (error) {
          console.error(error);
          setApiFetcher({ loading: false, error: error });
        }
      } else {
        setApiFetcher({ loading: false, error: null });
      }
    });
  };

  return (
    <Card ref={ref} title="Login" style={{ maxWidth: 640, margin: '2em' }}>
      {error && <div>{JSON.stringify(error)}</div>}
      <Form onSubmit={handleSubmit}>
        <FormItem
          label="Email"
          validateStatus={userNameError ? 'error' : ''}
          help={userNameError || ''}
        >
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'โปรดใส่อีเมล' }],
          })(<Input placeholder="user@tcaster.net" />)}
        </FormItem>
        <FormItem
          label="Password"
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError || ''}
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'โปรดใส่รหัส' }],
          })(<Input type="password" />)}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
            loading={loading}
          >
            Login
          </Button>
        </FormItem>
      </Form>
    </Card>
  );
});

const LoginFormWithForm = Form.create()(LoginForm);

export default LoginFormWithForm;
