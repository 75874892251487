import fetch2, { METHOD } from '../../libs/fetch';

import config from '../../utils/config';
import firebase from '../../libs/Firebase';

export const sendPush = async (data) => {
  try {
    console.log(data);
    const user = firebase.auth().currentUser;
    const userToken = await user.getIdToken(false);
    const tokenResp = await fetch2(config.tokenEndpoint, METHOD.POST, null, userToken);

    const pushMessage = {
      title: data.title,
      subtitle: 'TCASter',
      type: data.type,
      content: data.content,
    };

    const { topic } = data;
    const apiPath = topic.v === 1 ? config.pushV1Endpoint + topic.topic : config.pushV2Endpoint;

    if (topic.v !== 1) {
      // v2
      pushMessage.topicType = topic.topicType;
      pushMessage.topicId = topic.topicId;
    }

    console.log(pushMessage);
    const fetchResp = await fetch2(apiPath, METHOD.POST, pushMessage, tokenResp.token);
    return fetchResp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
